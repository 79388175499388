import moment from 'moment'
import { weekdays } from './datetime'

const stringToMomentTime = (str) => {
    if (!str || !str.includes(':')) return moment()
    return moment()
        .hours(str.split(':')[0])
        .minutes(str.split(':')[1] || 0)
}

const slotToDateTime = (slot) => {
    const opening = stringToMomentTime(slot[0])
    const closing = stringToMomentTime(slot[1])

    // Add a day if closing is past midnight
    if (closing.isBefore(opening) || closing.isSame(opening)) {
        closing.add(1, 'day')
    }

    return {
        opening,
        closing,
    }
}

export const openToday = (hours) => {
    const date = moment().format('YYYY-MM-DD')
    const dayOfTheWeek = moment
        .localeData('en')
        .weekdays(moment())
        .toLowerCase()

    let hourstoday = hours && hours[dayOfTheWeek]

    if (hours.exceptions && hours.exceptions[date]) {
        hourstoday = hours.exceptions[date]
    }

    if (!hourstoday || !hourstoday.length) {
        return {
            hours: null,
            opening: null,
            closing: null,
            now: false,
            later: false,
        }
    }

    // is one of the slot now
    const now = hourstoday.some((slot) => {
        const { opening, closing } = slotToDateTime(slot)
        return moment().isBetween(opening, closing)
    })

    // is one of the slot now
    const later = hourstoday.some((slot) => {
        const { opening } = slotToDateTime(slot)
        return moment().isBefore(opening)
    })

    // find the first slot open now or later
    let nextSlot = hourstoday.find((slot) => {
        const { opening, closing } = slotToDateTime(slot)
        return (
            moment().isBetween(opening, closing) || moment().isBefore(opening)
        )
    })

    // of there are no slots now or later show first opening and last closing
    if (!nextSlot) {
        nextSlot = [hourstoday[0][0], hourstoday[hourstoday.length - 1][1]]
    }

    return {
        hours: hourstoday,
        opening: nextSlot[0],
        closing: nextSlot[1],
        now,
        later,
    }
}

const OPEN = 0
const CLOSED = 1

export const useHoursField = (field) => {
    const days = moment.weekdays(true)
    const openinghours = JSON.parse(JSON.stringify(field.value || {})) // clone before editing (otherwise encore/form wont detect changes)

    const isValid = (value) => {
        const { exceptions, ...days } = value
        const merged = { ...(exceptions || {}), ...days }

        // test if there are null values left in opening times
        return Object.values(merged)
            .filter((value) => !!value)
            .every((slots) =>
                slots.every(
                    (slot) => slot[OPEN] !== null && slot[CLOSED] !== null
                )
            )
    }

    const handleChange = (value) => {
        field.setValue(value, isValid(value))
    }

    const onAddDate = (date) => {
        if (!openinghours.exceptions) openinghours.exceptions = {}
        openinghours.exceptions[moment(date).format('YYYY-MM-DD')] = []
        handleChange(openinghours)
    }

    const actionsForDay = (localeDay) => {
        const day = weekdays[moment().day(localeDay).isoWeekday() % 7]

        const slots = openinghours[day] || []
        const isOpen = !!slots.length
        const lastSlot = slots[slots.length - 1]

        return {
            day,
            slots,
            isOpen,
            lastSlot,
            localeDay,

            handleSwitch: (checked) => {
                if (checked) {
                    openinghours[day] = [[null, null]]
                } else {
                    openinghours[day] = []
                }

                handleChange(openinghours)
            },

            handleTimeChange: (slot, openOrClose, value) => {
                openinghours[day][slot][openOrClose] = value
                handleChange(openinghours)
            },

            handleAddSlot: () => {
                openinghours[day].push([null, null])
                handleChange(openinghours)
            },

            handleDeleteSlot: (index) => {
                openinghours[day].splice(index, 1)
                handleChange(openinghours)
            },
        }
    }

    const actionsForDate = (date) => {
        const slots = openinghours.exceptions[date] || []
        const isOpen = !!slots.length
        const lastSlot = slots[slots.length - 1]

        return {
            date,
            slots,
            isOpen,
            lastSlot,

            handleSwitch: (checked) => {
                if (checked) {
                    openinghours.exceptions[date] = [[null, null]]
                } else {
                    openinghours.exceptions[date] = []
                }

                handleChange(openinghours)
            },

            handleTimeChange: (slot, openOrClose, value) => {
                openinghours.exceptions[date][slot][openOrClose] = value
                handleChange(openinghours)
            },

            handleAddSlot: () => {
                openinghours.exceptions[date].push([null, null])
                handleChange(openinghours)
            },

            handleDeleteSlot: (index) => {
                openinghours.exceptions[date].splice(index, 1)
                handleChange(openinghours)
            },

            handleChangeDate: (newDate) => {
                openinghours.exceptions[newDate] = openinghours.exceptions[date]
                delete openinghours.exceptions[date]
                handleChange(openinghours)
            },

            handleDeleteDate: () => {
                delete openinghours.exceptions[date]
                handleChange(openinghours)
            },
        }
    }

    return {
        days,
        openinghours,
        isValid,
        handleChange,
        onAddDate,
        actionsForDay,
        actionsForDate,
    }
}
