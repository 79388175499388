import classnames from 'classnames'
import React, { useCallback } from 'react'

import { ReactComponent as LocateIcon } from '../../../svg/locate.svg'

import '../../../scss/react/map/user-location.scss'
import { useShopperLocation } from '../../context/shopper'

interface Props {
    className?: string
    large?: boolean
    children?: React.ReactNode
}

const AskPermissionButton = ({ className, large, children }: Props) => {
    const { declined, unavailable, place, position, locating, getPosition } =
        useShopperLocation()

    const handleDeterminePosition = useCallback(
        (ev) => {
            ev.preventDefault()

            // when user has declined geolocation and we have no place
            // restart the page
            if (!place && declined) {
                return global.location.reload()
            }
            getPosition()
        },
        [declined, getPosition, place]
    )

    // No functionality for this button if we have no place
    if (!place && unavailable) return null

    return (
        <button
            type="button"
            className={classnames(className, {
                'btn-large': large,
                locating,
                'is-located': place || position,
            })}
            onClick={handleDeterminePosition}
        >
            <LocateIcon />
            {children}
        </button>
    )
}

export default AskPermissionButton
