import { StandaloneSearchBox } from '@react-google-maps/api'
import { t } from '@zupr/i18n'
import classnames from 'classnames'
import { useCallback, useContext, useState } from 'react'

import { Loader } from './loader'

import { ReactComponent as Magnifier } from '../../../svg/search.svg'

import GeolocationContext from '../../context/geolocation'
import { useShopperLocation } from '../../context/shopper'
import PositionButton from './permission-button'

import '../../../scss/react/map/places.scss'

const Places = () => {
    const [searchBox, setSearchBox] =
        useState<google.maps.places.SearchBox>(null)

    const [hasResults, setHasResults] = useState(false)
    const [hasFocus, setHasFocus] = useState(false)

    const { gBounds, viewport, isGmLoaded } = useContext(GeolocationContext)

    const { declined, position, locating, place, setPlace } =
        useShopperLocation()

    let placeholder = t('Plaats, buurt, adres, etc.', 'dutch')

    if (!hasFocus && !hasResults && !declined && position) {
        placeholder = t('Current location')
    }

    if (locating) {
        placeholder = t('Zoekt naar locatie...', 'dutch')
    }

    const handlePlacesChanged = useCallback(() => {
        const places = searchBox.getPlaces()
        if (places.length && places[0].geometry.location) {
            setPlace(places[0])
        }
    }, [searchBox, setPlace])

    return (
        <div
            className={classnames('google-places', {
                'is-search-available': hasResults && hasFocus,
            })}
        >
            <div className="google-places-holder">
                {isGmLoaded && (
                    <StandaloneSearchBox
                        onLoad={setSearchBox}
                        onPlacesChanged={handlePlacesChanged}
                        bounds={gBounds || viewport}
                    >
                        <input
                            autoComplete="off"
                            onFocus={() => setHasFocus(true)}
                            onBlur={() => setHasFocus(false)}
                            onChange={(ev) =>
                                setHasResults(!!ev.target.value.length)
                            }
                            type="text"
                            placeholder={
                                place?.formatted_address || placeholder
                            }
                        />
                    </StandaloneSearchBox>
                )}

                {!isGmLoaded && (
                    <input
                        autoComplete="off"
                        onFocus={() => setHasFocus(true)}
                        onBlur={() => setHasFocus(false)}
                        onChange={(ev) =>
                            setHasResults(!!ev.target.value.length)
                        }
                        type="text"
                        placeholder={place?.formatted_address || placeholder}
                    />
                )}

                <Magnifier
                    className={classnames('magnifier', {
                        'is-searching': hasFocus,
                        'has-result': hasResults,
                    })}
                />

                <PositionButton className="btn-user-location" />
            </div>
        </div>
    )
}

const PlacesLoader = () => (
    <Loader renderLoading={<Places />}>
        <Places />
    </Loader>
)

export default PlacesLoader
